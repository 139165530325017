<template>
  <div class="cart-page">
    <div class="cart-page__bg-wrapper cart-page__top">
      <span class="cart-page__bg"></span>
      <div class="cart-page__bg-content">
        <Header />
        <div class="cart-page__list">
          <Title>Список отчетов к заказу</Title>
          <Button class="add-order" @click.native="$router.push('/')">
            +
          </Button>
          <template v-if="orders.length">
            <div class="lists" :class="{ 'disabled-form': disabledForm }">
              <div
                v-for="order in orders"
                :key="order.object.CADNOMER"
                class="order-list"
                :class="{ 'invalid-order': isOrderInvalid(order.services) }"
              >
                <div class="order-cadnumber">
                  {{ order.object.CADNOMER }} - {{ order.object.ADDRESS }}
                </div>
                <div class="order-types">
                  <div v-if="checkCadQuarter(order.object.TYPE)">
                    <h5 class="cad-quarter">
                      Указанный вами кадастровый номер соответствует
                      кадастровому кварталу. По данному типу можно заказать
                      только отчет об объектах недвижимости в пределах
                      кадастрового плана территории
                    </h5>
                    <Checkbox v-model="order.services.KPT" :disabled="true">
                      Отчет об объектах недвижимости в пределах кадастрового
                      плана территории
                    </Checkbox>
                  </div>
                  <template v-else>
                    <div class="checkbox-wrapper">
                      <Checkbox
                        v-model="order.services.XZP"
                        :disabled="order.services.ARS"
                      >
                        Отчет об основных параметрах объекта недвижимости, в том
                        числе проверка собственника (собственников), ограничение
                        прав и обременения
                      </Checkbox>
                    </div>
                    <div class="checkbox-wrapper">
                      <Checkbox
                        v-model="order.services.SOPP"
                        :disabled="order.services.ARS"
                      >
                        Отчет об изменениях прав на объект недвижимости
                      </Checkbox>
                    </div>
                    <div class="checkbox-wrapper">
                      <Checkbox
                        v-model="order.services.SKS"
                        :disabled="order.services.ARS"
                      >
                        Отчет об установлении и/или изменении кадастровой
                        стоимости объекта недвижимости
                      </Checkbox>
                    </div>
                  </template>
                </div>
                <div class="order-delete">
                  <button
                    @click.prevent="deleteOrder(order.object.CADNOMER)"
                  ></button>
                </div>
              </div>
            </div>
            <Button
              v-if="!showForm"
              class="cart-btn-order_back"
              :disabled="!isOrderValid"
              @click.native="toOrder"
            >
              Перейти к оформлению заказа
            </Button>
            <Button
              v-else
              class="cart-btn-order_back"
              @click.native="changeOrder"
            >
              Изменить заказ
            </Button>
          </template>
          <div v-else class="cart-empty">
            <h3>Ваша корзина пуста</h3>
          </div>
        </div>
        <FormCart v-if="showForm" :orders="orders" />
      </div>
    </div>
    <Footer />
    <Loader v-if="false" />
  </div>
</template>

<script>
import Header from '@/components/uniq/header/Header';
import Title from '@/components/gui/title/Title';
import Loader from '@/components/views/main/loader/Loader.vue';
import Button from '@/components/gui/button/Button';
import FormCart from '@/components/views/cart/form-cart/FormCart';
import Checkbox from '@/components/gui/checkbox/Checkbox';

import './cart.scss';

export default {
  name: 'Cart',
  components: {
    Header,
    Title,
    Loader,
    FormCart,
    Checkbox,
    Button,
    Footer: () => import('@/components/uniq/footer/Footer')
  },
  data() {
    return {
      showForm: false,
      disabledForm: false
    };
  },
  computed: {
    orders() {
      return this.$store.state.cartData;
    },
    isOrderValid() {
      return this.orders.every(order => {
        return !this.isOrderInvalid(order.services);
      });
    }
  },
  mounted() {
    window.scrollTo(0,0);
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    },

    isOrderInvalid(services) {
      return !(services.ARS || services.XZP || services.SOPP || services.SKS);
    },
    selectARS(order) {
      if (!order.services.ARS) {
        order.services.XZP = false;
        order.services.SOPP = false;
        order.services.SKS = false;
      }
    },
    checkCadQuarter(type) {
      return type == 'Кадастровый квартал';
    },
    toOrder() {
      this.showForm = true;
      this.disabledForm = true;
      this.$store.commit('setCartData', this.orders);
      window.ym && window.ym(88990008, 'reachGoal', 'to_pay');
    },
    changeOrder() {
      this.showForm = false;
      this.disabledForm = false;
    },
    deleteOrder(cadNumber) {
      const newCartData = this.orders.filter(
        item => item.object.CADNOMER !== cadNumber
      );
      this.$store.commit('setCartData', newCartData);
    }
  }
};
</script>
