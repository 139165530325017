<template>
  <div class="form">
    <template>
      <PriceWithDiscount
        :selected-services="selectedServices"
        :price="price"
        :price-without-discount="priceWithoutDiscount"
      />
      <p class="form__info">
        Контактные данные
      </p>
      <form class="form__address" novalidate @submit.prevent="validate">
        <Field
          v-model="form.email"
          class="form__address-field"
          title="E-mail *"
          type="email"
          name="email"
          :error="formErrors.email"
        />
        <Phone
          v-model="form.phone"
          class="form__address-field"
          title="Телефон"
          :error="formErrors.phone"
        />
        <Button type="submit">
          Заказать
        </Button>
      </form>
    </template>
  </div>
</template>

<script>
import './form.scss';

import Button from '@/components/gui/button/Button';
import Field from '@/components/gui/field/Field';
import Phone from '@/components/gui/field/Phone';
import PriceWithDiscount from '@/components/gui/priceWithDiscount/PriceWithDiscount';

import Validation from '@/ext/validation/ValidationWithFocus';
import order from '@/api/order';

export default {
  name: 'FormCart',
  components: {
    Button,
    Field,
    Phone,
    PriceWithDiscount
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    orders: {
      type: Array
    }
  },
  data() {
    return {
      error: '',
      selectedServices: [],
      formRules: {
        email: [Validation.REQUIRED, Validation.EMAIL],
        phone: [Validation.PHONE]
      },
      form: {
        email: '',
        phone: ''
      },
      formErrors: {
        email: '',
        phone: ''
      },
      formIsSubmit: false
    };
  },
  computed: {
    cost() {
      return this.$store.state.config.cost;
    },
    price() {
      return this.selectedServices.length > 1
        ? this.priceWithoutDiscount * 0.8
        : this.priceWithoutDiscount;
    },
    priceWithoutDiscount() {
      let price = 0;

      this.selectedServices.forEach(type => {
        price += Number(this.cost[type]);
      });

      return price;
    }
  },
  watch: {
    formIsSubmit() {
      this.$watch('form', () => this.validate(false), { deep: true });
    }
  },
  mounted() {
    this.selectedServices = this.getTypesOfOrder();
  },
  methods: {
    getTypesOfOrder() {
      const arrTypes = [];

      this.orders.forEach(order => {
        for (const key in order.services) {
          if (Object.hasOwnProperty.call(order.services, key)) {
            order.services[key] && arrTypes.push(key);
          }
        }
      });

      return arrTypes;
    },
    validate(submit = true) {
      this.formIsSubmit = true;
      const selector = submit ? '.field_error input' : null;
      const validation = new Validation(
        selector,
        this.form,
        this.formRules
      ).make();

      this.formErrors = validation.errors;

      submit && validation.isValid && this.submit();
    },
    async submit() {
      const formData = {
        data: [],
        email: this.form.email,
        phone: this.form.phone
      };

      this.orders.forEach(order => {
        const cadNumber = order.object.CADNOMER;
        const types = [];

        for (const key in order.services) {
          if (Object.hasOwnProperty.call(order.services, key)) {
            const item = order.services[key];

            item && types.push(key);
          }
        }

        formData.data.push({ [cadNumber]: types });
      });

      const data = await order(formData);

      if (data.Success) {
        localStorage.setItem('payment_id', data.id)
        this.$router.push({
          name: 'Payment'
        })
      } else {
        alert('Ошибка! Попробуйте позже')
      }
    }
  }
};
</script>
